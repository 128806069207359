import React from "react"
import PropTypes from "prop-types"

const svgAssetsContext = require.context(
  `!file-loader!svgo-loader?{"plugins":[{"removeViewBox":false}]}!../assets`,
  true,
  /^\.\/.*\.svg$/
)

const LazySVGImg = ({ src, alt, ...rest }) => (
  <img src={svgAssetsContext(`./${src}`).default} alt={alt} {...rest} />
)

LazySVGImg.propTypes = {
  src: PropTypes.string,
}

export default LazySVGImg
