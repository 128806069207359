import React from "react"
import PropTypes from "prop-types"

import { smallCapsStyles } from "../utils/styles"

const HeadlineSmallCaps = ({ children, withHr, hrColor, divId, className }) => {
  return (
    <h3
      css={theme => [
        smallCapsStyles(theme),
        {
          fontSize: theme.fontSizes[2],
          color: theme.colors.purple[30],
          position: `relative`,
          lineHeight: theme.lineHeights.loose,
          letterSpacing: `0.125em`,
          fontWeight: `normal`,
          paddingRight: theme.space[10],
          marginBottom: theme.space[10],
          display: `flex`,
        },
      ]}
      className={className}
      id={divId}
    >
      <span
        css={theme => ({
          display: `inline-block`,
          flex: `0 0 auto`,
          position: `relative`,
          paddingRight: theme.space[10],
          zIndex: 1,
          "&:after": {
            background: theme.colors.gatsby,
            content: `" "`,
            height: 1,
            position: `absolute`,
            right: 0,
            top: `50%`,
            width: theme.space[9],
          },
        })}
      >
        {children}
      </span>
      <span
        css={theme => ({
          flex: ` 1 1 100%`,
          position: `relative`,

          ...(withHr && {
            "&:after": {
              background: hrColor ? hrColor : `${theme.colors.gatsby}6f`,
              content: `" "`,
              height: 1,
              position: `absolute`,
              left: 0,
              right: 0,
              top: `50%`,
            },
          }),
        })}
      />
    </h3>
  )
}

HeadlineSmallCaps.propTypes = {
  background: PropTypes.string,
  hrColor: PropTypes.string,
  withHr: PropTypes.bool,
  divId: PropTypes.string,
}

export default HeadlineSmallCaps
